import { cloneDeep } from "lodash"

import {
  DEFAULT_THEME_STYLES,
  DEFAULT_IMAGES,
  DEFAULT_META
} from "../constants"

export class ThemeFactory {
  /** @returns {import('../types').Theme } */
  static create() {
    // We do `cloneDeep` to prevent mutation of default values
    return cloneDeep({
      name: "Theme",
      styles: DEFAULT_THEME_STYLES,
      images: DEFAULT_IMAGES,
      meta: DEFAULT_META
    })
  }
}
