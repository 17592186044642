// eslint-disable-next-line no-unused-vars
import { TournamentGame } from "./TournamentGame"

/**
 * @typedef {Object} RoundGame
 * @property {number} [endTimestamp]
 */

export class RoundManager {
  /**
   *
   * @param {RoundGame[]} games
   */
  constructor(round, games) {
    /** @type {TournamentGame} */
    this.round = round
    /** @type { RoundGame[] } */
    this.games = games
  }

  /**
   * @param {RoundGame} game
   */
  isGameOver(game) {
    return (
      game.endTimestamp !== undefined &&
      game.endTimestamp < Date.now() &&
      game.endTimestamp !== 0
    )
  }

  isOver() {
    return this.games.length > 0 && this.games.every(this.isGameOver)
  }

  get gamesCount() {
    return this.games.length
  }
}
