<template>
  <div>
    <section>
      <h3>Colors:</h3>
      <ul>
        <li v-for="(color, key) in theme.styles" :key="key">
          <code>{{ key }}</code
          >: <ThemeColorPreview :value="color" />
        </li>
      </ul>
    </section>
    <section>
      <h3>Images:</h3>
      <ul>
        <li v-for="(value, key) in theme.images" :key="key">
          <code>{{ key }}</code
          >:
          <img
            v-if="typeof value === 'string'"
            :src="value"
            width="24"
            height="24"
          />
          <template v-else
            ><img
              v-for="image in value"
              :key="image"
              :src="image"
              width="24"
              height="24"
          /></template>
        </li>
      </ul>
    </section>
    <section>
      <h3>Meta:</h3>
      <ul>
        <li v-for="(value, key) in theme.meta" :key="key">
          <code>{{ key }}</code
          >: {{ value }}
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import Vue from "vue"

import ThemeColorPreview from "./ThemeColorPreview"

export default Vue.extend({
  name: "ThemePreview",
  components: {
    ThemeColorPreview
  },
  props: {
    theme: {
      /** @type {import('vue').PropType<import('../types').Theme>} */
      type: Object,
      required: true
    }
  }
})
</script>
