import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "ThemeColorPreview",
  functional: true,
  props: {
    value: {
      type: String,
      required: true
    }
  },
  render(h, { props, data }) {
    return h(
      "span",
      mergeData(data, {
        style: { backgroundColor: props.value },
        staticClass: "inline-block w-6 h-6 align-middle"
      })
    )
  }
})
