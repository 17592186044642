import { sortBy } from "lodash"

// eslint-disable-next-line no-unused-vars
import { TournamentGame } from "./TournamentGame"
import { TournamentGameStatus } from "./TournamentGameStatus"

export class Tournament {
  /**
   * @param { { id: string, games?: TournamentGame[], playersCount?: number, playersPerTeam?: number } } options
   */
  constructor(options) {
    this.id = options.id
    /** @type {TournamentGame[]} */
    this.games = options.games.sort((a, b) => a.timestamp - b.timestamp) || []
    this.playersCount = options.playersCount || 0
    this.playersPerTeam =
      options.playersPerTeam || Tournament.DEFAULT_PLAYERS_PER_TEAM
    for (const game of this.games) {
      game.injectTournament(this)
    }
  }

  /**
   * @param {TournamentGame} game
   */
  addGame(game) {
    game.injectTournament(this)
    this.games.push(game)
  }

  getGames() {
    return this.games
  }

  /**
   * @returns {TournamentGame[]}
   */
  getSortedGames() {
    return sortBy(this.games, game => game.timestamp)
  }

  /**
   * @param {string} gameID
   */
  getGame(gameID) {
    return this.games.find(game => game.id === gameID)
  }

  /**
   * @param {TournamentGame} game
   */
  getGameIndex(game) {
    return this.getSortedGames().indexOf(game)
  }

  getPreviousGame(gameID) {
    const game = this.getGame(gameID)
    const idx = this.games.indexOf(game)
    if (idx === 0) return null
    return this.games[idx - 1]
  }

  getNextGame(gameID) {
    const game = this.getGame(gameID)
    const idx = this.games.indexOf(game)

    if (idx + 1 >= this.games.length) return null
    return this.games[idx + 1]
  }

  canDeleteGame() {
    return (
      this.games.length > 0 &&
      this.games[this.games.length - 1].status !== TournamentGameStatus.STARTED
    )
  }

  deleteGame() {
    return this.games.pop()
  }

  clone() {
    return new Tournament({
      id: this.id,
      games: this.games.map(game => game.clone()),
      playersCount: this.playersCount,
      playersPerTeam: this.playersPerTeam
    })
  }

  static DEFAULT_PLAYERS_PER_TEAM = 5
}
